/* eslint-disable prefer-rest-params */
/* eslint-disable unicorn/prefer-spread */
/* eslint-disable no-multi-assign */
/* eslint-disable func-names */

export function loadPendo(token) {
  if (!token) {
    return;
  }

  if (document.querySelector('script[src*="pendo.js"]')) {
    return;
  }

  // This code is related to Pendo(https://app.pendo.io), it has been autogenerated
  (function (p, e, n, d, o) {
    let w;
    let x;
    o = p[d] = p[d] || {};
    o._q = o._q || [];
    const v = ["initialize", "identify", "updateOptions", "pageLoad", "track"];
    for (w = 0, x = v.length; w < x; ++w) {
      (function (m) {
        o[m] =
          o[m] ||
          function () {
            o._q[m === v[0] ? "unshift" : "push"]([m].concat(Array.prototype.slice.call(arguments, 0)));
          };
      })(v[w]);
    }
    const y = e.createElement(n);
    y.async = !0;
    y.src = "https://cdn.pendo.io/agent/static/" + token + "/pendo.js";
    const z = e.getElementsByTagName(n)[0];
    z.parentNode.insertBefore(y, z);
  })(window, document, "script", "pendo");
}
