import "./index.css";
import { Spinner } from "@shopify/polaris";
import React, { ReactNode, useEffect } from "react";
import { loadUser } from "@vendor-app/app/_state/actionCreators/initialize";
import { useActiveOrganizationSelector } from "@vendor-app/app/_state/reducers/organizations";
import { useSmartrrVendorDispatch, useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";
import { initPendo } from "@vendor-app/external/pendo";
import { useRequiredAuthRedirects } from "@vendor-app/utils/shopify/handleRequiredAuthRedirects";

interface Props {
  children?: ReactNode;
}

export function AdminAuthContainer({ children }: Props): JSX.Element {
  const user = useSmartrrVendorSelector(state => state.auth.user);
  const userIsLoading = useSmartrrVendorSelector(state => state.auth.isLoading);
  const { activePlan, isLoadingActivePlan } = useSmartrrVendorSelector(state => state.accountPlans);
  const activeOrg = useActiveOrganizationSelector();
  const dispatch = useSmartrrVendorDispatch();
  const areProductsLoading = useSmartrrVendorSelector(state => state.purchasables).isLoading;
  const isRedirecting = useRequiredAuthRedirects(userIsLoading, user);

  useEffect(() => {
    if (!user && userIsLoading) {
      dispatch(loadUser());
    }
  }, []);

  useEffect(() => {
    if (!userIsLoading && !isRedirecting && activeOrg && user) {
      // pendo loaded into browser occurs in frontend/src/main
      initPendo({ authUser: user, activePlan, setup: activeOrg.setup, shopifyData: activeOrg.shopifyStoreData });
    }
  }, [userIsLoading, isRedirecting, activeOrg]);

  if (isRedirecting || userIsLoading || isLoadingActivePlan || areProductsLoading) {
    return (
      <div className="loader-container">
        <Spinner />
      </div>
    );
  }

  return <React.Fragment>{children}</React.Fragment>;
}
